import { db } from '@flowby/shared-firebase'
import { useLocation, useParams } from 'react-router-dom'
import GroupView from '../components/Group/GroupView'
import Loader from '../components/shared/Loader'
import Error from '../components/shared/Error'
import { useCollData, useDocData } from '../libs/firebaseHooks'
import { useTranslation } from 'react-i18next'

export default function Group() {
  const params = useParams()
  const { t } = useTranslation()
  const store = params.store as string
  const doesNotExistTranslation = t('group-does-not-exist')
  const groupIsEmptyTranslation = t('group-is-empty')
  // TODO: Handle queue missing from route
  const group = params.group as string
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const kiosk = (queryParams.get('kiosk') as boolean | null) || false
  const [groupData, groupLoading] = useDocData(db.getGroupRef(store, group), [store, group])
  const [queuesData, queuesLoading] = useCollData(db.getQueuesRef(store), [store])

  if (groupLoading || queuesLoading) {
    return <Loader />
  }

  if (!groupData) {
    return <Error text={doesNotExistTranslation} />
  }
  if (!groupData.queues || groupData.queues.length === 0) {
    return <Error text={groupIsEmptyTranslation} />
  }
  if (!queuesData) {
    return <Error />
  }

  return <GroupView store={store} groupData={groupData} queuesData={queuesData} kiosk={kiosk} />
}
