import { Navigate, useParams } from 'react-router-dom'
import { DataFields, db, PiiCustomData } from '@flowby/shared-firebase'
import Error from '../components/shared/Error'
import Loader from '../components/shared/Loader'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useCollData, useDocData } from '../libs/firebaseHooks'
import DataInput from '../components/Queue/DataInput'
import QueueView from '../components/Queue/QueueView'

export default function QueuePage() {
  const { t } = useTranslation()
  const doesNotExistTranslation = t('does-not-exist')
  const params = useParams()
  const store = params.store as string
  const queue = params.queue as string
  const queueNumberId = params.queuenumberid as string
  const [dataInput, setDataInput] = useState<null | PiiCustomData['data']>(null)

  const [queueData, queueDataLoading] = useDocData(db.getQueueRef(store, queue), [store, queue])
  const [groupsData] = useCollData(db.getGroupsRef(store), [store])
  const [queueNumberData, queueNumberDataLoading] =
    useDocData(db.getQueueNumberRef(store, queue, queueNumberId), [store, queue, queueNumberId])
  const [queueNumbersData, queueNumbersDataLoading] = useCollData(db.getQueueNumbersRef(store, queue), [store, queue])

  const hasCustomDataFields =
    queueData && queueData.customDataFields && Object.keys(queueData.customDataFields).length > 0


  // if data loading
  if (queueDataLoading || queueNumberDataLoading || queueNumbersDataLoading) {
    return <Loader />
  }

  // if data not found or error
  if (!queueData) {
    return <Error text={doesNotExistTranslation} />
  }

  // if queue closed
  if (
    !queueData.state ||
    queueData.state.status === 'closed' ||
    (queueData.state.status === 'closing' && !queueNumberId)
  ) {
    return <Navigate to={`/${store}/q/${queue}/thanks?queue_closed=true`} />
  }

  // if input required before entering queue
  if (
    !queueNumberId &&
    hasCustomDataFields &&
    !dataInput
  ) {
    return <DataInput
      store={store}
      queueData={queueData}
      customDataFields={queueData.customDataFields as DataFields}
      setInputData={setDataInput}
    />
  }

  if (!queueNumbersData) {
    return <Error />
  }

  const queueNumbersBefore =
    queueNumberData ? queueNumbersData.filter(
      qnr => qnr.queueNumber && queueNumberData.queueNumber && qnr.queueNumber < queueNumberData.queueNumber
    ).length : 0

  return <QueueView
    store={store}
    queue={queue}
    queueData={queueData}
    queueNumberId={queueNumberId}
    queueNumberData={queueNumberData}
    queueNumbersBefore={queueNumbersBefore}
    groupsData={groupsData}
  />
}
