import { useParams, useLocation } from 'react-router-dom'
import Status from '../components/Status'
import Footer from '../components/shared/Footer'
import { Stack } from '@chakra-ui/react'
import { db } from '@flowby/shared-firebase'
import Loader from '../components/shared/Loader'
import { useCollData } from '../libs/firebaseHooks'

// TODO: Move to have status page per queue and group
export default function StatusPage() {
  // TODO: Handle store undefined
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const params = useParams()
  const store = params.store as string
  const queues = queryParams.get('queues')
  const groups = queryParams.get('groups')
  const queuesQuery = queues && queues.split('|')
  const groupsQuery = groups && groups.split('|')
  const [queuesData, queuesLoading] = useCollData(db.getQueuesRef(store), [store])
  const [groupsData, groupsLoading] = useCollData(db.getGroupsRef(store), [store])

  // TODO: handle undefined queue data better
  if (!queuesData || queuesLoading || groupsLoading) {
    return <Loader />
  }

  let queuesInGroups: string[] = []
  if (groupsQuery && groupsData) {
    queuesInGroups = groupsData
      .filter(g => groupsQuery.includes(g.shortName))
      .reduce((acc: string[], curr) => {
        return acc.concat(curr.queues)
      }, [])
  }
  const selectedQueues = queuesQuery ? queuesQuery.concat(queuesInGroups) : queuesInGroups
  const displayQueuesData =
    selectedQueues.length > 0 && queuesData ? queuesData.filter(q => selectedQueues.includes(q.shortName)) : queuesData

  return (
    <Stack>
      <Status store={store} selectedQueuesData={displayQueuesData} allQueuesData={queuesData} groupsData={groupsData} />
      <Footer />
    </Stack>
  )
}
