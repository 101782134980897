import { useOnlineStatus } from '../../libs/connection'
import { ToastId, useToast as useChakraToast } from '@chakra-ui/toast'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function OfflineToast() {
  const toast = useChakraToast()
  const online = useOnlineStatus()
  const toastRef = useRef<ToastId>()
  const [showingToast, setShowingToast] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (online && showingToast) {
      setShowingToast(false)
      if (toastRef.current) {
        toast.close(toastRef.current)
        toast({
          title: t('online-toast'),
          status: 'success',
          duration: 3000,
          position: 'top',
        })
      }
    }
    if (!online && !showingToast) {
      setShowingToast(true)
      toastRef.current = toast({
        title: t('offline-toast'),
        status: 'warning',
        duration: null,
        position: 'top',
      })
    }
  }, [online, showingToast])
  return null
}