import { db } from '@flowby/shared-firebase'
import { useParams } from 'react-router-dom'
import QueueKioskView from '../components/Kiosk/QueueKioskView'
import Error from '../components/shared/Error'
import Loader from '../components/shared/Loader'
import { useCollData, useDocData } from '../libs/firebaseHooks'

export default function KioskPage() {
  const params = useParams()
  const store = params.store as string
  const queue = params.queue as string

  const [queueData, queueLoading] = useDocData(db.getQueueRef(store, queue), [store, queue])
  const [storeData, storeLoading] = useDocData(db.getStoreRef(store), [store])
  const [groupsData, groupsLoading] = useCollData(db.getGroupsRef(store), [store])

  if (storeLoading || queueLoading || groupsLoading) {
    return <Loader />
  }

  if (!queueData || !storeData || !groupsData) {
    return <Error />
  }

  return <QueueKioskView
    store={store}
    queue={queue}
    queueData={queueData}
    groupsData={groupsData}
    storeData={storeData}
  />
}
