import { useEffect, useState } from 'react'
import { isIOS } from 'react-device-detect'
import { Flex, Text } from '@chakra-ui/react'

export default function Footer({ size = 'md' }: { size?: string }) {
  const [bottomMargin, setBottomMargin] = useState(16)

  useEffect(() => {
    function resize() {
      const addressBarVisible = window.innerHeight === document.documentElement.clientHeight
      let margin = 4
      if (isIOS && addressBarVisible) {
        margin = 20
      } else if (addressBarVisible) {
        margin = 16
      }

      setBottomMargin(margin)
    }
    window.onresize = resize
  }, [])

  return (
    <Flex mb={bottomMargin} alignItems="center" justifyContent="center">
      <Text fontSize={size}>Powered by flowby.io</Text>
    </Flex>
  )
}
