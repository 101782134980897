import { Flex, Heading } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

export default function Error({ text }: { text?: string }) {
  const { t } = useTranslation()
  return (
    <Flex mt="40vh" alignItems="center" justifyContent="center" textAlign="center" data-testid="loader">
      <Heading size="md">{text || t('something-went-wrong')}</Heading>
    </Flex>
  )
}
