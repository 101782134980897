import { useState } from 'react'
import ConfirmAlert from '../shared/ConfirmAlert'
import { Stack, Button, Text, useDisclosure, Box } from '@chakra-ui/react'
import { db, QueueNumberData } from '@flowby/shared-firebase'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export default function UnorderedQueue({
  store,
  queue,
  queueNumberData,
  queueNumberId,
}: {
  store: string
  queue: string
  queueNumberData: QueueNumberData
  queueNumberId: string
}) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [leaveQueueLoading, setLeaveQueueLoading] = useState(false)
  const leaveQueueDisclosure = useDisclosure()

  async function handleLeaveQueue() {
    setLeaveQueueLoading(true)
    await db.leaveQueue(store, queue, queueNumberId)
    navigate(`/${store}/q/${queue}/thanks?queue_number_id=${queueNumberId}&leaver=true`)
    setLeaveQueueLoading(false)
  }

  return (
    <Stack flex={1}>
      <ConfirmAlert
        disclosure={leaveQueueDisclosure}
        headerText={t('leave-queue-question')}
        content={<Text>{t('leave-queue-instruction')}</Text>}
        noText={t('no')}
        yesText={t('leave-queue')}
        yesLoading={leaveQueueLoading}
        yesAction={handleLeaveQueue}
      />
      <Stack spacing={4} textAlign="center" alignItems="center" flex={1} justifyContent="center">
        <Stack minW="xs" spacing={4} alignItems="center" p={7} shadow="md" borderWidth="1px" bg="white" borderRadius="0.375rem">
          <Text fontSize="2xl" textTransform="uppercase" fontWeight="bold">
            {t('your-queue-number')}
          </Text>
          <Box display="inline-block" bgColor="gray.200" borderRadius="0.375rem" px={4}>
            <Text fontWeight="extrabold" fontSize="7xl" data-testid="my-queue-number">
              {queueNumberData.queueNumber === 0 ? '-' : queueNumberData.queueNumber}
            </Text>
          </Box>
        </Stack>
        <Text fontSize="xl" maxW="xs">{t('we-will-get-to-you-soon')}</Text>
      </Stack>

      <Stack alignItems="center" justifyContent="flex-end" px={2}>
        <Button
          className="plausible-event-name=Queue+Leave"
          height={20}
          fontSize={26}
          w="100%"
          maxW="xl"
          onClick={leaveQueueDisclosure.onOpen}
        >
          {t('leave-queue')}
        </Button>
      </Stack>
    </Stack>
  )
}
