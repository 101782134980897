import { useEffect, useState } from 'react'
import ModalContainer from '../shared/ModalContainer'
import {
  Flex,
  Box,
  IconButton,
  Text,
  Input,
  InputGroup,
  Button,
  useDisclosure,
  useToast,
  InputRightElement,
  InputLeftAddon,
  Link,
} from '@chakra-ui/react'
import { CheckIcon, SmallCloseIcon } from '@chakra-ui/icons'
import { FaBell, FaRegBell } from 'react-icons/fa'
import { db, QueueNumberData, QueueData, SupportedLangs } from '@flowby/shared-firebase'
import { useTranslation } from 'react-i18next'
import validatePhone from 'phone'
import ReactFlagsSelect from 'react-flags-select'
import { countryCallingCodes } from '../../libs/data'
import i18next from 'i18next'
import { useDocData } from '../../libs/firebaseHooks'

export default function Notification({
  store,
  queue,
  queueData,
  queueNumberData,
  queueNumberId,
  queueNumbersBefore
}: {
  store: string
  queue: string
  queueData: QueueData
  queueNumberData?: QueueNumberData
  queueNumberId: string
  queueNumbersBefore: number
}) {
  const { t } = useTranslation()
  const phoneNumberTranslation = t('phone-number')
  const [storeData] = useDocData(db.getStoreRef(store), [store])
  const country = storeData && storeData.country ? storeData.country : 'SE'
  const userNotificationEnabled = Boolean(queueNumberData && queueNumberData.phoneSet)
  const notificationQuestionAsked = queueNumberData?.phoneSet !== undefined
  const notifyWhenTurnsUntilMyTurn =
    queueData.config && queueData.config.notifyWhenTurnsUntilMyTurn !== undefined
      ? queueData.config.notifyWhenTurnsUntilMyTurn
      : 1

  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(undefined)
  const [validPhone, setValidPhone] = useState<boolean | null>(null)

  const notificationDisclosure = useDisclosure()
  const toast = useToast()
  useEffect(() => {
    if (!userNotificationEnabled && !notificationQuestionAsked && queueNumbersBefore >= notifyWhenTurnsUntilMyTurn) {
      setTimeout(() => notificationDisclosure.onOpen(), 1100)
      db.setPhoneAsked(store, queue, queueNumberId)
    }
  }, [queue])

  function savePhoneNumber(phoneNumber: string) {
    notificationDisclosure.onClose()
    const internationalizedPhoneNumber = countryCallingCodes[country].secondary + phoneNumber

    if (internationalizedPhoneNumber) {
      toast({
        position: 'top',
        title: t('will-be-notified', { phone: internationalizedPhoneNumber }),
        status: 'success',
        duration: 1000,
      })
      db.storePhoneNumber(queueNumberId, store, queue, internationalizedPhoneNumber, i18next.language as SupportedLangs)
    }
  }

  let notifyInstructions = t('notify-instructions-0')
  if (notifyWhenTurnsUntilMyTurn === 1) {
    notifyInstructions = t('notify-instructions-1')
  } else if (notifyWhenTurnsUntilMyTurn === 2) {
    notifyInstructions = t('notify-instructions-2')
  }

  let notifyInstructionsAlt = t('notify-instructions-alt-0')
  if (notifyWhenTurnsUntilMyTurn === 1) {
    notifyInstructionsAlt = t('notify-instructions-alt-1')
  } else if (notifyWhenTurnsUntilMyTurn === 2) {
    notifyInstructionsAlt = t('notify-instructions-alt-2')
  }


  return (
    <Flex justifyContent="flex-end">
      <ModalContainer
        size="lg"
        isOpen={notificationDisclosure.isOpen}
        onClose={notificationDisclosure.onClose}
        header={t('notify-me')}
        content={
          userNotificationEnabled ? (
            <>
              <Text>{notifyInstructionsAlt}</Text>
            </>
          ) : queueNumbersBefore >= notifyWhenTurnsUntilMyTurn ? (
            <Box data-testid="notification-modal">
              <Text>{notifyInstructions}</Text>
              <InputGroup mt={4} mb={1}>
                <InputLeftAddon
                  bgColor="inherit"
                  style={{ paddingLeft: 4, paddingRight: 4 }}
                  children={
                    <ReactFlagsSelect
                      customLabels={countryCallingCodes}
                      className="home-flags-container"
                      selectButtonClassName="home-flags"
                      id="home-flags-select"
                      searchable={true}
                      searchPlaceholder="Search countries..."
                      selected={country}
                      fullWidth={false}
                      showSelectedLabel={false}
                      showSecondaryOptionLabel={false}
                      onSelect={() => { }}
                      disabled={true}
                    />
                  }
                />

                <Input
                  data-testid="notification-modal-phone-input"
                  name="phone"
                  type="tel"
                  placeholder={phoneNumberTranslation}
                  value={phoneNumber}
                  onChange={e => {
                    const phone = countryCallingCodes[country].secondary + e.target.value
                    const validPhone = validatePhone(phone, { strictDetection: false })
                    setValidPhone(validPhone.isValid)
                    if (validPhone.isValid) {
                      setPhoneNumber(validPhone.phoneNumber.replace(validPhone.countryCode, ''))
                    } else {
                      setPhoneNumber(e.target.value)
                    }
                  }}
                />
                {validPhone !== null && (
                  <InputRightElement
                    children={
                      validPhone ? <CheckIcon color="green.500" /> : <SmallCloseIcon w={6} h={6} color="red.500" />
                    }
                  />
                )}
              </InputGroup>
              <Text color="gray.700" fontSize="sm">
                {t('privacy-notice')}{' '}
                <Link
                  color="teal.600"
                  isExternal={true}
                  href={process.env.REACT_APP_ENV === 'dev' ? 'https://dev.flowby.io/privacy/' : 'https://flowby.io/privacy/'}
                >
                  {t('privacy-policy')}
                </Link>
                .
              </Text>
            </Box>
          ) : (
            <>
              <Text>{t('notify-instructions-next-in-line')}</Text>
            </>
          )
        }
        footer={
          userNotificationEnabled ? (
            <Flex justifyContent="flex-end">
              <Button variant="outline" mr={2} onClick={notificationDisclosure.onClose}>
                {t('close')}
              </Button>
              <Button
                onClick={() => {
                  db.removeUserPhoneNumber(queueNumberId, store, queue)
                  setPhoneNumber(undefined)
                  notificationDisclosure.onClose()
                }}
              >
                {t('delete')}
              </Button>
            </Flex>
          ) : queueNumbersBefore >= notifyWhenTurnsUntilMyTurn ? (
            <Flex justifyContent="flex-end">
              <Button
                data-testid="notification-modal-no"
                variant="outline"
                mr={2}
                onClick={notificationDisclosure.onClose}
              >
                {t('no-thanks')}
              </Button>
              <Button
                data-testid="notification-modal-yes"
                disabled={!phoneNumber || !validPhone}
                onClick={() => phoneNumber && savePhoneNumber(phoneNumber)}
              >
                {t('submit')}
              </Button>
            </Flex>
          ) : (
            <Flex justifyContent="flex-end">
              <Button variant="outline" mr={2} onClick={notificationDisclosure.onClose}>
                {t('close')}
              </Button>
            </Flex>
          )
        }
      />
      <IconButton
        size="md"
        className="plausible-event-name=Queue+Notification+Open"
        aria-label="Enable notifications"
        pl={1}
        onClick={notificationDisclosure.onOpen}
        color="brand.100"
        fontSize="2xl"
        variant="ghost"
        icon={userNotificationEnabled ? <FaBell /> : <FaRegBell />}
      />
    </Flex>
  )
}
