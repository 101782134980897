import {
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
  ModalBody,
  ModalFooter,
  UseModalProps,
} from '@chakra-ui/react'

export default function ModalContainer({
  isOpen,
  onClose,
  header,
  content,
  footer,
  size = 'full',
}: {
  isOpen: UseModalProps['isOpen']
  onClose: UseModalProps['onClose']
  header: string
  content: JSX.Element
  footer?: JSX.Element
  size?: string
}) {
  return (
    <Modal trapFocus={false} isCentered onClose={onClose} size={size} isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent mt={0}>
        <ModalHeader pr={12}>{header}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{content}</ModalBody>
        {footer && <ModalFooter>{footer}</ModalFooter>}
      </ModalContent>
    </Modal>
  )
}
