import { Flex, Heading, Text, Container } from '@chakra-ui/react'

export default function NotFound() {
  return (
    <Flex textAlign="center" justifyContent="center" pb={20} direction="column" minH={'95vh'}>
      <Container>
        <Heading>404</Heading>
        <Text>The page was not found.</Text>
      </Container>
    </Flex>
  )
}
