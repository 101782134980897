import { Box, Stack, Button, Text } from '@chakra-ui/react'
import { QueueData, SupportedLangs } from '@flowby/shared-firebase'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'

export default function MyTurnPage({ queueData, myNumber, onClose }: {
  queueData: QueueData
  myNumber: string
  onClose?: () => void
}) {
  const { t } = useTranslation()
  function getYourTurnMessage() {
    const defaultValue = t('go-to-cashier')
    const lang = i18next.language as SupportedLangs
    const yourTurnString = queueData.translations[lang]?.yourTurn
    return yourTurnString || defaultValue
  }

  return (
    <Stack alignItems="center" spacing={4} flex={1}>
      <Text fontSize="2xl">{t('your-turn')}</Text>
      <motion.div
        animate={{ scaleY: 1.1, scaleX: 1.1 }}
        transition={{ ease: 'linear', duration: 0.4, repeat: Infinity, delay: 0, repeatType: 'reverse' }}
      >
        <Box borderRadius={10} bgColor="brand.300">
          <Box bgColor="green.200" borderRadius="0.375rem" px={6}>
            <Text fontWeight="bold" fontSize="7xl" data-testid="my-turn">
              {myNumber}
            </Text>
          </Box>
        </Box>
      </motion.div>
      <Stack textAlign="center" spacing={0}>
        <Text fontSize="2xl">{getYourTurnMessage()}</Text>
      </Stack>
      {onClose && (
        <Button onClick={onClose}>{t('close')}</Button>
      )}
    </Stack>
  )
}
