import { CollRef, db, DocRef, FireStoreError } from "@flowby/shared-firebase"
import { useEffect, useState } from "react"

export function useDocData<T>(ref: DocRef<T>, deps: any[]) {
  const [data, setData] = useState<T | undefined>(undefined)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<FireStoreError | undefined>(undefined)
  useEffect(() => {
    setData(undefined)
    setLoading(true)
    setError(undefined)
    const unsubscribe = db.onChange(ref, (data) => {
      setData(data.data())
      setLoading(false)
      setError(undefined)
    }, (error) => {
      setError(error)
      setLoading(false)
    })
    return unsubscribe
  }, deps)
  const r: [T | undefined, boolean, FireStoreError | undefined] = [data, loading, error]
  return r
}

export function useCollData<T>(ref: CollRef<T>, deps: any[]) {
  const [data, setData] = useState<T[] | undefined>(undefined)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<FireStoreError | undefined>(undefined)
  useEffect(() => {
    setData(undefined)
    setLoading(true)
    setError(undefined)
    const unsubscribe = db.onChange(ref, (data) => {
      setData(data.docs.map((doc) => doc.data()))
      setLoading(false)
      setError(undefined)
    }, (error) => {
      setError(error)
      setLoading(false)
    })
    return unsubscribe
  }, deps)
  const r: [T[] | undefined, boolean, FireStoreError | undefined] = [data, loading, error]
  return r
}