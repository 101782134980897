import { useRef } from 'react'
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
} from '@chakra-ui/react'

export default function ConfirmAlert({
  id,
  disclosure,
  headerText,
  content,
  noText,
  yesText,
  yesLoading = false,
  yesAction,
  noAction = disclosure.onClose,
}: {
  id?: string
  disclosure: {
    isOpen: boolean
    onOpen: () => void
    onClose: () => void
    onToggle: () => void
    isControlled: boolean
    getButtonProps: (props?: unknown) => unknown
    getDisclosureProps: (props?: unknown) => unknown
  }
  headerText: string
  content: JSX.Element
  noText: string
  yesText: string
  yesLoading?: boolean
  yesAction: () => void
  noAction?: () => void
}) {
  const noRef = useRef(null)
  return (
    <AlertDialog
      id={id ? id : undefined}
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      leastDestructiveRef={noRef}
      isCentered={true}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {headerText}
          </AlertDialogHeader>

          <AlertDialogBody>{content}</AlertDialogBody>

          <AlertDialogFooter>
            <Button data-testid={id ? `${id}-no` : undefined} ref={noRef} variant="outline" onClick={noAction}>
              {noText}
            </Button>
            <Button data-testid={id ? `${id}-yes` : undefined} isLoading={yesLoading} onClick={yesAction} ml={3}>
              {yesText}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
